import React, { useEffect, useState } from 'react';
import './NavModal.scss';
import { Col, Modal, Row, Table, Tabs, TabsProps, ColProps } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { TurnOverMonthly } from './TurnOverMonthly/TurnOverMonthly';
import { TurnOverProvider } from './TurnOverProvider/TurnOverProvider';
import { MaxBet } from './MaxBet/MaxBet';
import { LangSwitch } from 'components/Common/LangSwitch/LangSwitch';
import { useUserAccount } from 'api/useUserAccount';
import { LogoutOutlined } from '@ant-design/icons';
import { color } from 'framer-motion';
import bannerShare from "assets/banner-share.png";
import IntlMessages from 'components/Common/IntlMessages';
export interface NavModalProps {
  isModalOpen: boolean,
  onClose: () => void
}

const colButtonModalStyle: ColProps = {
  xs: { span: 12 },
  sm: { span: 12 },
  md: { span: 12 },
  lg: { span: 6 },
  xxl: { span: 6 },
};

const dataSource = [
  {
    casino: 'SAGaming',
    amount: '20,000.00 ฿'
  },
  {
    // style={{ top: 2, fontSize: '3px !important' }}
    casino: 'Dream Gaming',
    // bodyStyle={{ height: '92vh', padding: 0 }}
    amount: '20,000.00 ฿'
  },
  // Add more data here
];

const columns = [
  {
    title: 'คาสิโนสด',
    dataIndex: 'casino',
    key: 'casino'
  },
  {
    title: 'ยอดเดิมพัน',
    dataIndex: 'amount',
    key: 'amount'
  }
];

const items: TabsProps['items'] = [
  {
    key: '1',
    label: 'Turn Over',
    children: <TurnOverMonthly />,
  },
  {
    key: '2',
    label: <IntlMessages id="nav.turnOverType" />,
    children: <TurnOverProvider />,
  },
  {
    key: '3',
    label: <IntlMessages id="nav.maxBet" />,
    children: <MaxBet />,
  },
];


export const NavModal: React.FC<NavModalProps> = ({
  isModalOpen = false,
  onClose
}) => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [modalOpen, setIsModalOpen] = useState(false);
  const account = useUserAccount()
  const agentDomain = process.env.REACT_APP_AUTO_DOMAIN
  useEffect(() => {
    setIsModalOpen(isModalOpen)
  }, [isModalOpen]);

  const handleCancel = () => {
    // setIsModalOpen(false);
    onClose()
  };

  return (
    <Modal
      key={'nav'}
      title={null}
      centered
      open={modalOpen}
      className='nav-modal'
      footer={null}
      width={700}
      // styles={styles}
      // style={{ top: 2, fontSize: '3px !important' }}
      onCancel={handleCancel}
    // bodyStyle={{ height: '92vh', padding: 0 }}
    >
      <div className='switch-language'><LangSwitch /></div>
      <div style={{ width: '100%', textAlign: 'center' }}>
        <img src={'/assets/logo.webp'} alt="logoimg" width={"100%"} className='logoNav' />
      </div>

      <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', textAlign: 'center' }}>
        {/* {!account.account ? (
          <Row style={{ width: '100%' }}>
            <Col span={12} style={{padding: '20px'}}>
              <button type="button" className="regis-btn" data-toggle="modal" data-dismiss="modal" data-target="#registerModal">
                <div className="-typo-wrapper">
                  <div className="-typo">สมัครเลย</div>
                </div>

              </button>
            </Col>
            <Col span={12} style={{padding: '20px'}}>
              <button type="button" className="regis-btn" data-toggle="modal" data-dismiss="modal" data-target="#registerModal">
                <div className="-typo-wrapper">
                  <div className="-typo">เข้าสู่ระบบ</div>
                </div>

              </button>
            </Col>
          </Row>
        ) : (
          <div className='welcome-user' style={{ width: '100%' }}>
            <h2>ยินดีต้อนรับคุณ</h2>
            <div className="show-username">
              <span>{account.account.user}</span>
            </div>
          </div>
        )} */}


        {account.account && (
          <div className='welcome-user' style={{ width: '100%' }}>
            {/* ยินดีต้อนรับคุณ {account.user} */}
            <h2><IntlMessages id="nav.welcome" /></h2>
            <div className="show-username">
              <span>{account.account.user}</span>
            </div>
          </div>
        )}

        <div style={{ width: '100%', textAlign: 'center' }}>
          {account.account && (
            <div style={{ padding: '14px' }}>
              <Link to="./logout" className="logout-btn" style={{ color: '#f04d5d', marginLeft: '-15px' }} >
                <LogoutOutlined style={{ paddingRight: '10px' }} />   <IntlMessages id="nav.logout" />
              </Link>
            </div>
          )}
        </div>
        {account.account && (
          <Row gutter={[8, 8]} style={{ width: '100%', marginTop: 18 }}>
            <Col {...colButtonModalStyle}>
              <button  onClick={() => window.location.href = `${agentDomain}`}  type="button" className="menu-btn" data-toggle="modal" data-dismiss="modal" data-target="#registerModal">
                <div className="-typo-wrapper">
                  <div className="-typo"><IntlMessages id="nav.topup" /></div>
                </div>

              </button>
            </Col>
            <Col {...colButtonModalStyle}>
              <button type="button" onClick={() => {
                onClose()
                navigate('/history?page=statement')
              }} className="menu-btn" data-toggle="modal" data-dismiss="modal" data-target="#registerModal">
                <div className="-typo-wrapper">
                  <div className="-typo"><IntlMessages id="nav.transaction" /></div>
                </div>
              </button>
            </Col>
            <Col {...colButtonModalStyle}>
              <button type="button"
                onClick={() => {
                  onClose()
                  navigate('/history?page=bet')
                }}
                className="menu-btn" data-toggle="modal" data-dismiss="modal" data-target="#registerModal">
                <div className="-typo-wrapper">
                  <div className="-typo"><IntlMessages id="nav.betHistory" /></div>
                </div>
              </button>
            </Col>
            <Col {...colButtonModalStyle}>
              <button type="button"
                onClick={() => {
                  onClose()
                  navigate('/history?page=bet')
                }}
                className="menu-btn" data-toggle="modal" data-dismiss="modal" data-target="#registerModal">
                <div className="-typo-wrapper">
                  <div className="-typo"><IntlMessages id="nav.sportHistory" /></div>
                </div>

              </button>
            </Col>
          </Row>
        )}
        <Row>
          <Col span={24}>
            <Link to="" className="social-share" target="_blank">
              <img alt="xx" className='w-100' src={bannerShare} />
            </Link>
          </Col>
        </Row>
        {/* <div style={{ width: '100%', textAlign: 'center' }}>
          <LangSwitch />
          {account.account && (
            <div style={{paddingTop:'10px'}}>
              <Link to="./logout" className="logout-btn" style={{ color: 'indianred', marginLeft: '-15px'}} >
                <LogoutOutlined style={{paddingRight:'10px'}}/>   ออกจากระบบ
              </Link>
            </div>
          )}

        </div> */}
        {account.account && (
          <Row style={{ width: '100%' }}>
            <Col span={24} style={{ padding: '.5rem' }}>
              <Tabs className='tabs-login' key={'keemaiduay'} defaultActiveKey="1" items={items} />
            </Col>
          </Row>
        )}
      </div>
    </Modal>
  )
}
